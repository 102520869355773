import { generateSessionStorage, generateStorage } from "@toss/storage";

export const sessionStorage = generateSessionStorage();
export const tossLocalStorage = generateStorage();

export const storageKeys = {
  ProductKey: "productKey",
  Account: "account",
  Email: "email",
  Name: "name",
  Ci: "ci",
  Phone: "phone",
  Gender: "gender",
  Address: "address",
  Agreement: "agreement",
  Birthdate: "birthdate",
  CouponCode: "couponCode",
  CouponIssuer: "couponIssuer",
  CouponTimeLeft: "couponTimeLeft",
  IsTossDC10: "is_toss_dc10",
  CouponTimeLeftTossDC10: "coupon_time_left_toss_dc10",
  isPayco: "is_payco",
  isConfirmContractVisit: "is_confirm_contract_visit",
  adisonClickKey: "adison_click_key",
  isNpay: "is_npay",
  PhoneVerificationCode: "phone_verification_code",
  benefitType: "benefit_type",
} as const;
